export const useCartValidation = () => {
  const { cart, removeItem } = useCartWithSubdomainLogic()
  const interval = ref()

  const handleCartItemWasUpdated = (data: unknown) => {
    if (!data?.item) return
    const price = data.item.price
    const originalPrice = data.item.original_price
    const id = data.item.id

    const outdatedItem = cart.value.items.find((item) => item.id === id)

    if (!outdatedItem) return
    outdatedItem.price = price
    outdatedItem.originalPrice = originalPrice
    outdatedItem.wasUpdated = true
  }

  const handleNotPublicError = (data: unknown) => {
    const item = cart.value.items.find((x) => data?.date.id === x.id)
    if (!item) return
    removeItem(item)
  }

  const handleValidationErrors = (e: unknown) => {
    const itemErrorHandlers = {
      cartItemWasUpdated: handleCartItemWasUpdated,
      notPublic: handleNotPublicError,
    }

    if (!e?.data) return

    if (e.data.reason === 'cartItemsValidation' && e.data.errors) {
      e.data.errors.forEach((err: unknown) => {
        itemErrorHandlers[err.reason]?.(err)
      })
    }
  }

  const performValidation = () => {
    if (cart.value.items && cart.value.items.length === 0) return

    kftApiRequest('/v1/checkout/cart/validate', {
      body: {
        items: cart.value.items.map((item) => ({
          ...item,
          product: item.id,
          price: (parseInt(item.price.amount) / 100).toFixed(2),
        })),
      },
    }).catch((e) => {
      handleValidationErrors(e)
    })
  }

  const setupValidationPolling = () => {
    performValidation()
    const oneMinute = 60000
    interval.value = setInterval(performValidation, oneMinute * 10)
  }
  const deactivateValidationPolling = () => {
    clearInterval(interval.value)
  }

  return {
    setupValidationPolling,
    performValidation,
    deactivateValidationPolling,
  }
}
